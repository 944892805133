<template>
  <div>
    <Admins></Admins>
  </div>
</template>

<script>
import Admins from "@/components/admin/admins";
export default {
  components: { Admins },
};
</script>

<style>
</style>