<template>
  <div>
    <h3>
      {{ $t("documentManagementSystemAdmin") }}
      <span class="text-muted">{{ count }}</span>
    </h3>
    <p class="text-muted">{{ $t("managersDescription") }}</p>
    <div
      class="d-flex justify-content-end"
      v-if="activeUser.role === 'Manager'"
    >
      <button class="blue-button" @click="adminShowModal = true">
        {{ $t("adminAdd") }}
      </button>
    </div>
    <Alert :alert="alert" class="mt-2" />
    <BaseTable
      i18n
      :loading="isLoading"
      :items="items"
      :count="count"
      :limit="10"
      @change:search="SET_SEARCH"
      :page="page"
      @change:page="SET_PAGINATION"
      :searchPlaceholder="$t('searchCustomerPlaceholder')"
      :pages="pages"
      :headers="headers"
      noCaption
    >
      <template #fullname="{ item }">
        <strong>
          <font-awesome-icon :icon="faUser" />

          {{ item.name }} {{ item.surname }}</strong
        >
        <div>
          <small class="text-muted">
            <font-awesome-icon :icon="faCalendar" />

            {{ setDate(item.createdDate) }}</small
          >
          <small class="text-muted">
            -
            <a class="d-inline-block" :href="`mailto:${item.email}`">
              <font-awesome-icon :icon="faEnvelope" />

              {{ item.email }}</a
            ></small
          >
        </div>
      </template>
      <template #phoneNo="{ item }">
        <a :href="`tel:${item.phoneNo}`">
          <span class="badge badge-soft-dark px-1 w-100">
            <font-awesome-icon :icon="faPhone" />
            {{ item.phoneNo }}</span
          >
        </a>
      </template>
      <template #isActive="{ item }">
        <div class="text-right text-md-left">
          <div class="position-relative">
            <small
              :class="`w-100 badge badge-soft-${
                item.isActive ? 'success' : 'danger'
              }`"
              >{{ $t(item.isActive ? $t("active") : $t("notActive")) }}</small
            >
          </div>
        </div></template
      >
      <template #actions="{ item }">
        <div class="d-flex justify-content-end actions-button">
          <div class="mr-3" @click="() => editAdminId(item.id)">
            <span class="badge badge-soft-dark px-1">
              <font-awesome-icon :icon="faPencil" />
            </span>
          </div>
          <div @click="() => deleteAdmin(item.id)">
            <span class="badge badge-soft-dark px-1">
              <font-awesome-icon :icon="faTrash" />
            </span>
          </div>
        </div>
      </template>
    </BaseTable>
    <b-modal
      centered
      v-model="adminShowModal"
      :title="$t('adminAdd')"
      class="admin-list"
      hide-footer
      size="md"
      @hidden="resetModal"
    >
      <div class="form-style account-settings p-4">
        <Alert :alert="alert" />
        <b-form @submit.prevent="adminId === null ? addAdmin() : editAdmin()">
          <b-form-group>
            <label for="name">{{ $t("name") }}</label>
            <b-form-input
              type="text"
              id="name"
              v-model="$v.form.name.$model"
              :state="!$v.form.name.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.name.required">{{
              $t("validateMessages.pleaseName")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <label for="surname">{{ $t("surname") }}</label>
            <b-form-input
              type="text"
              id="surname"
              v-model="$v.form.surname.$model"
              :state="!$v.form.surname.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.surname.required">{{
              $t("validateMessages.pleaseSurname")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <div class="phone-form">
            <b-form-group class="phone-input">
              <p class="area-code">( +49 )</p>
              <label for="telephone">{{ $t("phoneNumber") }}</label>
              <b-form-input
                type="text"
                id="telephone"
                inputmode="tel"
                v-model="$v.form.phoneNumber.$model"
                :state="!$v.form.phoneNumber.$error"
                @input="acceptNumber"
                maxlength="16"
              />
              <b-form-invalid-feedback v-if="!$v.form.phoneNumber.required">{{
                $t("validateMessages.pleasePhoneNo")
              }}</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.form.phoneNumber.minLength">{{
                $t("validateMessages.phoneNumberLength")
              }}</b-form-invalid-feedback>
            </b-form-group>
          </div>
          <b-form-group>
            <label for="email">{{ $t("emailAddress") }}</label>
            <b-form-input
              type="email"
              inputmode="email"
              id="email"
              v-model="$v.form.email.$model"
              :state="!$v.form.email.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.email.required">{{
              $t("validateMessages.pleaseEmail")
            }}</b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.form.email.email">{{
              $t("validateMessages.pleaseEmailFormat")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="password-icon">
            <label for="password">{{ $t("password") }}</label>
            <b-form-input
              :type="!passEye ? 'password' : 'text'"
              id="password"
              v-model="$v.form.password.$model"
              :state="!$v.form.password.$error"
              placeholder="******"
            />
            <span @click="passEye = !passEye">
              <font-awesome-icon
                :icon="passEye ? faEye : faEyeSlash"
                class="input-icon"
              />
            </span>
            <b-form-invalid-feedback
              v-if="!$v.form.password.required && adminId === null"
              >{{
                $t("validateMessages.pleasePassword")
              }}</b-form-invalid-feedback
            >
          </b-form-group>
          <div class="mb-5" v-if="adminId !== null">
            <label class="mr-2 w-100">{{ $t("accountStatus") }}</label>
            <div
              class="row align-items-center mb-3 married-form-margin col-12 ml-0"
            >
              <div class="col-12 col-md-6 col-xl-6 active-radio-button">
                <div class="radio-button">
                  <input
                    type="radio"
                    id="active"
                    name="status"
                    :value="true"
                    v-model="form.isActive"
                  />
                  <label for="active" class="mr-2 w-100">{{
                    $t("active")
                  }}</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-6">
                <div class="radio-button">
                  <input
                    type="radio"
                    id="notActive"
                    name="status"
                    :value="false"
                    v-model="form.isActive"
                  />
                  <label for="notActive" class="mr-2 w-100">{{
                    $t("notActive")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row align-items-center mb-3 married-form-margin justify-content-center mt-4"
          >
            <div class="col-12 col-md-6 col-xl-6 active-radio-button">
              <div class="radio-button">
                <input
                  type="radio"
                  id="admin"
                  name="role"
                  value="Admin"
                  v-model="form.role"
                />
                <label for="ledig" class="mr-2 w-100">{{ $t("admin") }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
              <div class="radio-button">
                <input
                  type="radio"
                  id="manager"
                  name="role"
                  value="Manager"
                  v-model="form.role"
                />
                <label for="married" class="mr-2 w-100">{{
                  $t("manager")
                }}</label>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center w-100">
            <button class="blue-button w-100 mt-3">
              <span v-if="!loading">{{ $t("save") }}</span>
              <b-spinner
                variant="light"
                class="font-size-14"
                v-else
              ></b-spinner>
            </button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseTable from "../../base/Table/index.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEye,
  faPhone,
  faEnvelope,
  faCalendar,
  faUser,
  faEyeSlash,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getUsers } from "../../../services/modules/User";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  addAdminManager,
  deleteAdminManager,
  editAdminManager,
  getAdmins,
  infoAdminManager,
} from "../../../services/modules/Admin";
import Alert from "../../base/alert/Alert.vue";

const { required, email } = require("vuelidate/lib/validators");

export default {
  components: {
    BaseTable,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      faEye,
      faEyeSlash,
      faPhone,
      faUser,
      faCalendar,
      faEnvelope,
      faPencil,
      faTrash,
      search: "",
      status: -1,
      statusList: [
        { value: -1, text: "allStatus" },
        { value: 0, text: "waiting" },
        { value: 1, text: "reviewing" },
        { value: 2, text: "completed" },
      ],
      headers: [
        { title: "fullname", value: "fullname", col: 12, md: 6, lg: 5 },
        { title: "phone", value: "phoneNo", col: 3, md: 3, lg: 3 },
        { title: "status", value: "isActive", col: 3, md: 3, lg: 2 },
        { title: "", value: "actions", col: 3, md: 3, lg: 2 },
      ],
      count: 0,
      page: 1,
      items: [],
      limit: 10,
      isLoading: true,
      activeUpdateStatus: -1,
      adminShowModal: false,
      loading: false,
      form: {
        name: "",
        surname: "",
        password: "",
        email: "",
        phoneNumber: "",
        phoneNo: "",
        role: "Admin",
        isActive: true,
      },
      passEye: false,
      roles: [
        {
          title: "Admin",
          value: "Admin",
        },
        {
          title: "Manager",
          value: "Manager",
        },
      ],
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      adminId: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      surname: {
        required,
      },
      password: {
        required,
      },
      email: {
        required,
        email,
      },
      phoneNumber: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["activeUser"]),
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  methods: {
    async addAdmin() {
      this.loading = true;
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        const response = await addAdminManager(this.form);

        if (response.message === "OK") {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "saveSuccess";
          setTimeout(() => {
            location.reload();
          }, 3000);
        } else if (response.message === "NOT_FOUND") {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "sameAdmin";
        } else {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "notSaveSuccess";
        }

        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      }

      this.loading = false;
    },
    changeRole(val) {
      this.form.role = val;
    },
    async infoAdmin() {
      const response = await infoAdminManager(this.adminId);

      if (response.message === "OK") {
        this.form = {
          ...response.data,
          phoneNumber: response.data.phoneNo.substring(
            3,
            response.data.phoneNo.length
          ),
          phoneNo: response.data.phoneNo,
          password: null,
        };
        this.acceptNumber();
      }
    },
    editAdminId(val) {
      this.adminId = val;
      this.adminShowModal = true;
      this.infoAdmin();
    },
    async editAdmin() {
      this.loading = true;
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError || this.form.password === null) {
        const response = await editAdminManager(this.adminId, this.form);

        if (response.message === "OK") {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "successAdminMessage";

          setTimeout(() => {
            location.reload();
          }, 3000);
        } else {
          this.alert.show = true;
          this.alert.variant = "success";
          this.alert.message = "notSuccessAdminMessage";
        }
      }
      this.loading = false;

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    resetModal() {
      this.adminId = null;
      this.form = {
        name: "",
        surname: "",
        password: "",
        email: "",
        phoneNumber: "",
        phoneNo: "",
        role: "Admin",
        isActive: true,
      };
    },
    async deleteAdmin(val) {
      const response = await deleteAdminManager(val);

      if (response.message === "OK") {
        const index = this.items.findIndex((item) => item.id === val);
        this.items.splice(index, 1);
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "successAdminMessage";
      } else {
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "notSuccessAdminMessage";
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    SET_STATUS_FILTER() {
      this.page = 1;
      this.$router.push({
        query: { ...this.$route.query, page: 1, status: this.status },
      });
      this.getUsersList();
    },
    SET_SEARCH(search) {
      this.search = search;
      this.page = 1;
      this.$router.push({ query: { ...this.$route.query, search, page: 1 } });
      this.getUsersList(this.page, this.search, this.status);
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.push({ query: { ...this.$route.query, page } });
      this.getUsersList(this.page, this.search, this.status);
    },
    async getUsersList() {
      this.isLoading = true;
      const response = await getAdmins(this.page, this.search, "", this.limit);
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else {
        this.items = [];
        this.count = 0;
      }
      this.isLoading = false;
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    acceptNumber() {
      let x = this.form.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,12})/);

      this.form.phoneNumber = !x[2] ? x[1] : x[1] + (x[2] ? "-" + x[2] : "");

      this.form.phoneNo = x[0];
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(q.page)) this.page = Number(q.page);
    if (!isNaN(q.status)) this.status = Number(q.status);
    if (q.search) this.search = q.search;
    this.getUsersList();
  },
};
</script>

<style lang="scss">
.account-settings {
  .lang-select-box {
    border-radius: 20px;
    border: 2px solid #01295f;
    padding: 0 1rem;
  }
}
.status-option-dropdown {
  position: absolute;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 11;
  &__button {
    cursor: pointer;
  }
}
.password-icon {
  position: relative;

  .input-icon {
    position: absolute;
    top: 60%;
    right: 1.2rem;
    color: #01295f;
  }
}
.phone-form {
  .area-code {
    position: absolute;
    top: 52%;
    left: 20px;
  }

  .phone-input {
    position: relative;

    input {
      padding-left: 70px;
    }
  }
}
.radio-button {
  border: 1px solid #161640;
  padding: 0px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  flex-direction: row-reverse;

  input {
    height: 22px;
    width: 22px;

    &:focus {
      box-shadow: none;
    }
  }

  input[type="radio"]:checked ~ label {
    color: rgb(87, 198, 255);
  }

  input[type="radio"]:checked ~ .radio-button {
    border: 1px solid red;
  }

  input[type="radio"]:checked {
    position: relative;

    &::before {
      content: "\2713";
      position: absolute;
      background-color: rgb(87, 198, 255);
      color: white;
      height: 22px;
      width: 22px;
      border-radius: 50rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 36px;
  }
}

.actions-button {
  span {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .married-form-margin {
    .active-radio-button {
      margin-bottom: 20px;
    }
  }
}
</style>